<template>
  <div>
    <el-row class="nav">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>数据中心</el-breadcrumb-item>
        <el-breadcrumb-item>采购系统</el-breadcrumb-item>
        <el-breadcrumb-item>采购明细</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <el-row>
      <h4>采购明细</h4>
    </el-row>
    <el-row>
      <table-searcher
        ref="searcher"
        :params="searcherParams"
        @search="handleSearch"
      >
      <template slot="action">
        <el-button
          type="primary"
          size="small"
          @click="$refs['downloader'].show(getParams())"
          >下载</el-button>
        </template>
      </table-searcher>
    </el-row>
    <!-- <el-row>
      <el-table border stripe width="100%" :data="dataList" height="600">
        <el-table-column prop="contract_id" label="采购合同"></el-table-column>
        <el-table-column prop="_vendor" label="供应商"></el-table-column>
        <el-table-column prop="_user" label="业务员"></el-table-column>
        <el-table-column prop="created_at" label="下单日期"></el-table-column>
        <el-table-column prop="ship_date" label="交期"></el-table-column>
        <el-table-column prop="_step" label="状态"></el-table-column>
        <el-table-column prop="_product.mfn" label="MFN"></el-table-column>
        <el-table-column prop="_product.name" label="品名"></el-table-column>
        <el-table-column
          prop="_product.box_package_dimension.unit"
          label="单位"
        ></el-table-column>
        <el-table-column label="规格">
          <template slot-scope="scope">
            {{ scope.row._product.box_package_dimension.length }}<br />
            {{ scope.row._product.box_package_dimension.width }}<br />
            {{ scope.row._product.box_package_dimension.height }}<br />
          </template>
        </el-table-column>
        <el-table-column prop="price" label="单价"></el-table-column>
        <el-table-column prop="qty" label="数量"></el-table-column>
        <el-table-column prop="amount" label="金额"></el-table-column>
        <el-table-column prop="received_qty" label="交付数"></el-table-column>
        <el-table-column
          prop="received_amount"
          label="交付金额"
        ></el-table-column>
        <el-table-column
          prop="not_receive_qty"
          label="未交付数"
        ></el-table-column>
        <el-table-column
          prop="not_receive_amount"
          label="未交付金额"
        ></el-table-column>
        <el-table-column prop="vendor_sku" label="供应商货号"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column
          prop="_invoice_type"
          label="开票类型"
        ></el-table-column>
        <el-table-column prop="volume" label="体积"></el-table-column>
        <el-table-column prop="ctn" label="箱数"></el-table-column>
        <el-table-column prop="volume" label="总体积"></el-table-column>
      </el-table>
    </el-row> -->
    <god-table
      ref="table"
      prefetch
      paging
      :data-provider="dataProvider"
      height="calc(100vh - 324px)"
    >
      <el-table-column prop="order_id" label="采购单号"></el-table-column>
      <el-table-column prop="contract_id" label="合同号"></el-table-column>
      <el-table-column prop="vendor_name" label="供应商"></el-table-column>
      <el-table-column prop="user" label="采购员"></el-table-column>
      <el-table-column prop="updated_at" label="订单确认日"></el-table-column>
      <el-table-column prop="ship_date" label="交期"></el-table-column>
      <el-table-column prop="mfn" label="MFN"></el-table-column>
      <el-table-column prop="sku" label="SKU"></el-table-column>
      <el-table-column prop="vendor_sku" label="供应商货号"></el-table-column>
      <el-table-column prop="product_name" label="品名"></el-table-column>
      <el-table-column prop="product_unit" label="单位"></el-table-column>
      <el-table-column prop="product_dimension" label="规格"></el-table-column>
      <el-table-column prop="currency" label="币种"></el-table-column>
      <el-table-column prop="price" label="采购价"></el-table-column>
      <el-table-column prop="qty" label="数量"></el-table-column>
      <el-table-column prop="received_qty" label="交付数"></el-table-column>
      <el-table-column
        prop="un_received_qty"
        label="待交付数"
      ></el-table-column>
      <el-table-column prop="amount" label="采购金额"></el-table-column>
      <el-table-column
        prop="received_amount"
        label="交付金额"
      ></el-table-column>
      <el-table-column
        prop="un_receive_amount"
        label="待交付金额"
      ></el-table-column>
      <el-table-column prop="_invoice_type" label="开票类型"></el-table-column>
      <el-table-column prop="ctn_volume_m" label="单箱体积"></el-table-column>
      <el-table-column prop="ctn" label="总箱数"></el-table-column>
      <el-table-column prop="total_volume" label="总体积"></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
    </god-table>
    <xlsx-downloader ref="downloader" type="DCENTER_PURCHASE_DETAIL" />
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import TableSearcher, { formatSearch } from '@/components/table-searcher'
import { createTableSearcherParams } from './options'
import XlsxDownloader from '@/components/xlsx-downloader'
import GodTable, { modifiField } from '@/components/god-table'
import { API } from '@/service/api'

export default {
  components: {
    TableSearcher,
    GodTable,
    XlsxDownloader
  },
  setup () {
    const table = ref(null)
    const searcher = ref(null)
    const searcherParams = createTableSearcherParams()
    const searchQuery = ref(formatSearch(searcherParams))
    const getParams = () => {
      const d = searcher.value ? searcher.value.model : {}
      const model = modifiField(d, 'range', f => {
        return Array.isArray(f)
          ? {
            updated_at_from: f[0],
            updated_at_to: f[1]
          }
          : {}
      })

      searchQuery.value = model
      return searchQuery.value
    }

    return {
      table,
      searcher,

      searcherParams,
      searchQuery,

      getParams,

      handleSearch () {
        table.value.refresh()
      },
      dataProvider (pageData) {
        const params = getParams()
        return API.DataCenter.getPurchaseDetail({ ...params, ...pageData })
      }
    }
  }
}
</script>

import { cInput, cSelect, cDateRangePicker } from '@/components/form-item-gen'
import store from '@/store'

export function createTableSearcherParams () {
  return [
    // 采购单号
    cInput('order_id', '', {
      attrs: {
        class: 'w-40',
        placeholder: '采购单号'
      }
    }),
    cInput('contract_id', '', {
      attrs: {
        class: 'w-40',
        placeholder: '合同号'
      }
    }),
    cSelect('vendor_id', null, {
      attrs: {
        class: 'w-48',
        placeholder: '供应商',
        filterable: true,
        clearable: true
      },
      options: {
        key: 'id',
        dataProvider: () => store.getters.sysConfig.vendorList,
        attrGetter: it => {
          return {
            label: it.name,
            value: it.id
          }
        }
      }
    }),
    cSelect('is_recrived', null, {
      attrs: {
        class: 'w-28',
        placeholder: '交付与否',
        clearable: true
      },
      options: {
        key: 'id',
        dataProvider: () => [{ id: 0, name: '未交付' }, { id: 1, name: '已交付' }],
        attrGetter: it => {
          return {
            label: it.name,
            value: it.id
          }
        }
      }
    }),
    cInput('mfn', '', {
      attrs: {
        class: 'w-40',
        placeholder: 'MFN'
      }
    }),
    cDateRangePicker('range', [], {
      attrs: {
        style: 'width: 324px;',
        'value-format': 'yyyy-MM-dd',
        'range-separator': '至',
        'start-placeholder': '开始日期',
        'end-placeholder': '结束日期'
      }
    })
  ]
}
